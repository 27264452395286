<template>
    <modal :open="modalTarget" :loading="targetLoading" @close="$emit('closeModal')" @save="targetStarted" :modalData="modalData">
      <template v-slot:modalContent>
        <v-row align="start" justify="center" class="ma-0">
            <v-col cols="6">
                <div v-if="page !== 'extend'">Type</div>
                <v-select v-if="page !== 'extend'" v-model="targetModel.type" hide-details class="pt-0 pb-3" :items="targetTypeList" placeholder="Select Target"
                    :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}" @change="targetChange"></v-select>
                <v-text-field v-if="page !== 'extend'" placeholder="Target Name" hide-details="auto" class="pb-3" v-model.trim="targetModel.title"></v-text-field>
                <v-row class="ma-0 pa-0 my-5" align="center" :justify="page !== 'extend' ? 'space-between' : 'center'">
                    <v-col cols="12" v-if="page !== 'extend'" class="col-lg-6 mb-2 mb-lg-0 pa-0">
                        <v-row class="pa-0 ma-0" align="center" >
                            <v-col cols="12" class="d-flex ma-0 pa-0">
                                <vc-date-picker mode="time" class="target-starttimer time-picker-only start-date-timer-label" v-model="targetModel.start_time" :masks="{ L: 'YYYY-MM-DD HH:mm:ss' }"
                                    timezone="Australia/Melbourne" :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss' }"/>
                                <span class="d-none text-h5 mx-1 d-lg-flex align-center justify-center font-weight-black">-</span>
                            </v-col>
                        </v-row>
                        <vc-date-picker id="start-date" v-model="targetModel.start_time" mode="date" :masks="{ L: 'YYYY-MM-DD HH:mm:ss' }" timezone="Australia/Melbourne"
                            :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss' }" @dayclick="dateChange($event, 'start_time')" :is-required="true">
                                <template v-slot="{ togglePopover }">
                                    <v-btn class="d-none" id="target-startdate" @click.prevent="togglePopover"></v-btn>
                                </template>
                        </vc-date-picker>
                    </v-col>
                    <v-col cols="12" class="col-lg-5 pa-0">
                        <vc-date-picker mode="time" class="target-endtimer time-picker-only end-date-timer-label" v-model="targetModel.end_time" :masks="{ L: 'YYYY-MM-DD HH:mm:ss' }"
                            timezone="Australia/Melbourne" :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss' }"/>
                        <vc-date-picker id="end-date" v-model="targetModel.end_time" mode="date" :masks="{ L: 'YYYY-MM-DD HH:mm:ss' }" timezone="Australia/Melbourne"
                            :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss' }" @dayclick="dateChange($event, 'end_time')" :is-required="true">
                                <template v-slot="{ togglePopover }">
                                    <v-btn class="d-none" id="target-enddate" @click.prevent="togglePopover"></v-btn>
                                </template>
                        </vc-date-picker>
                    </v-col>
                </v-row>
                <v-row v-if="page !== 'extend' && ['C', 'U', 'M'].includes(targetModel.type)" class="ma-0 pa-0 pb-3" align="end">
                    <v-col cols="6" class="pa-0">
                        <v-text-field placeholder="Quantity" type="number" min="0" hide-details="auto" v-model="targetModel.field_1"></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pa-0 px-1 text-subtitle-1 d-flex justify-center">
                        donations of
                    </v-col>
                    <v-col cols="12" class="pa-0 pt-3">
                        <v-row>
                            <v-col cols="3" class="py-0">
                                <v-switch v-model="targetModel.once" dense label="Once" hide-details="auto" class="pt-0"
                                    :true-value="1" :false-value="0" @change="!targetModel.once && subChanges('o')"></v-switch>
                            </v-col>
                            <v-col cols="9" class="py-0 pl-5">
                                <v-text-field v-if="targetModel.once" placeholder="$ Amount" type="number" min="0" hide-details="auto"
                                    v-model.number="targetModel.field_2" class="pt-0"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="pa-0 pt-3">
                        <v-row>
                            <v-col cols="3" class="py-0">
                                <v-switch v-model="targetModel.monthly" dense label="Monthly" hide-details="auto" class="pt-0"
                                    :true-value="1" :false-value="0" @change="!targetModel.monthly && subChanges()"></v-switch>
                            </v-col>
                            <v-col cols="9" class="py-0 pl-5">
                                <v-text-field v-if="targetModel.monthly" placeholder="$ Amount" type="number" min="0" hide-details="auto"
                                    v-model.number="targetModel.field_3" class="pt-0"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row v-if="page !== 'extend' && ['G'].includes(targetModel.type)" class="ma-0 pa-0 pb-3" align="end">
                    <span class="text-subtitle-1 mr-3">Looking for</span>
                    <v-text-field class="w-10" placeholder="Quantity" type="number" min="0" hide-details="auto" v-model="targetModel.field_1"></v-text-field>
                    <span class="text-subtitle-1 mr-3">people to make a donation</span>
                </v-row>
                <v-row class="ma-0 pb-3" v-if="page !== 'extend' && ['DM'].includes(targetModel.type)" align="end">
                    <span class="text-subtitle-1 mr-3">Donations matched up to</span>
                    <v-text-field placeholder="$ Amount" hide-details="auto" type="number" min="0" v-model.number="targetModel.field_1"></v-text-field>
                </v-row>
                <v-row class="ma-0 pb-3" v-if="page !== 'extend' && ['GD'].includes(targetModel.type)" align="end">
                    <span class="text-subtitle-1 mr-3">Looking for</span>
                    <v-text-field placeholder="$ Target" hide-details="auto" type="number" min="0" v-model.number="targetModel.field_1"></v-text-field>
                    <span class="text-subtitle-1 ml-3">for</span>
                </v-row>
                <v-text-field  v-if="page !== 'extend' && ['GD'].includes(targetModel.type)" placeholder="e.g. $5000 or item" hide-details="auto" v-model="targetModel.field_2"></v-text-field>
                <v-row v-if="page !== 'extend' && ['U'].includes(targetModel.type)" class="ma-0" align="end">
                    <v-col cols="4" class="text-subtitle-1 pa-0">Unlocks</v-col>
                    <v-col cols="8" class="pa-0">
                        <v-text-field placeholder="e.g. $5000 or item" hide-details="auto" v-model="targetModel.unlocks"></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" class="pa-0 pl-16 pr-0">
                <v-alert type="info" elevation="0" colored-border class="pl-2 pr-0 ma-0 divider-left">
                    <v-icon slot="prepend" color="primary" class="mr-2">mdi-alert-circle</v-icon>
                    <v-card class="pa-0" color="transparent" elevation="0">
                      <v-card-text class="text-subtitle-2 font-weight-bold pa-0 pb-2">Note: Multiples are rounded down e.g. if once off amount is $100, then $250 equals 2 spots.</v-card-text>
                        <template v-for="(item, index) in items">
                        <v-card-title class="text-subtitle-1 font-weight-bold primary--text pa-0">{{ item.title }}</v-card-title>
                        <v-card-text class="text-subtitle-2 px-0" :class="{'pb-0': (index === items.length - 1)}">eg: {{ item.subtitle }}</v-card-text>
                      </template>
                    </v-card>
                </v-alert>
            </v-col>
        </v-row>
      </template>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props:["page", "modalTarget"],
    emits:["closeModal"],
    components:{
        Modal: () => import("@/components/common/modal"),
    },
    data: (vm) => ({
        modalData: {
            title: 'create new challenge',
            saveBtnTxt: 'Start',
            width: 1000,
            maxHeight: '500px'
        },
        targetLoading: false,
        target: '',
        items: [
            {
                title: 'Challenge',
                subtitle: '10 donations of $200 single gift or $20 per month. Multiples count.',
            }, {
                title: 'Unlock Challenge',
                subtitle: '10 donations of $200 single gift or $20 per month unlocks $5,000. Multiples count.',
            }, {
                title: 'Dollar for Dollar Match',
                subtitle: 'all donations of any amount matched up to $10,000 (includes single gift or first monthly gift).',
            }, {
                title: 'General Dollar Amount',
                subtitle: 'looking to raise a total of $37,000 for new microwave link (includes single gift or first monthly gift).',
            }, {
                title: 'Matching Challenge',
                subtitle: 'Must get 10 donations of $200 single gift or $20 per month for all donations to be matched. DOES NOT COUNT multiples.',
            }, {
                title: 'Best Gift Challenge',
                subtitle: 'looking for 10 people to make a donation of any amount (includes single gift or first monthly gift).',
            },
        ],
        targetModel: {
            type: 'C',
            title: null,
            start_time: null,
            end_time: null,
            unlocks: null,
            field_1: null,
            field_2: null,
            field_3: null,
            once: 0,
            monthly: 0
        },
    }),
    computed: {
        ...mapGetters(["currentTargetModel", "targetTypeList"])
    },
    created() {
        if (this.page === 'edit' || this.page === 'extend') {
            this.targetModel.id = null;
            this.targetModel = this.$helpers.insertObjValue(this.targetModel, this.currentTargetModel);
            if (['C', 'U', 'M'].includes(this.targetModel.type)) {
                this.targetModel.once = this.targetModel.field_2 ? 1 : 0;
                this.targetModel.monthly = this.targetModel.field_3 ? 1 : 0;
            }
            this.modalData.saveBtnTxt =  'Update';
            this.modalData.title = 'update challenge';
        }
    },
    mounted() {
        setTimeout(() => {
            if (this.page === 'edit' || this.page === 'extend') {
              if(!this.currentTargetModel || this.page === 'extend'){
                this.targetModel.end_time =  this.$helpers.addHoursnDDateJs('hours',1);
              }
            } else {
                this.targetModel.start_time = new Date();
                this.targetModel.end_time = this.$helpers.addHoursnDDateJs('hours',1);
            }
            $('.target-starttimer .vc-time-icon').click(function(event){
                event.stopPropagation();
                $(`#target-startdate`).click();
            });
            $('.target-endtimer .vc-time-icon').click(function(event){
                event.stopPropagation();
                $(`#target-enddate`).click();
            });
        }, 1000);
    },
    methods: {
        ...mapActions(["createTarget", "updateTarget"]),

        dateChange(day, field) {
            this.targetModel[field] = field === 'start_time' ? new Date(day.id) : this.$helpers.addHoursnDDateJs('hours', 1, day.id);
        },
        targetChange() {
            this.targetModel = {...this.targetModel, ...{unlocks: null, field_1: null, field_2: null, field_3: null, once: 0, monthly: 0}}
        },
        targetStarted() {
            this.targetLoading = true;
            if(['C', 'U', 'M'].includes(this.targetModel.type) && !this.targetModel.field_2 && !this.targetModel.field_3) {
                this.$store.commit("snackbar/SHOW_MESSAGE", {
                    text: `${(this.targetModel.once && this.targetModel.monthly) || (!this.targetModel.once && !this.targetModel.monthly)  ? 'Once or Monthly' :
                        this.targetModel.once ? 'Once' :  'Monthly' } Amount is required`,
                    color: "error",
                });
                this.targetLoading = false;
                return;
            }
            if (this.page === 'edit' || this.page === 'extend') {
                let reqData = this.targetModel;
                if(this.page === 'extend'){
                  reqData = { id: this.targetModel.id, end_time: this.targetModel.end_time };
                }

              this.updateTarget(reqData).then((response) => this.responseUpdate(response, 'Updated')).catch(() => this.targetLoading = false);
            } else {
                this.createChallenge();
            }
        },
        createChallenge() {
            this.targetModel.status = 1;
            this.targetModel.start_time = this.$helpers.setMomentValues(this.targetModel.start_time, 'second', 0, 'YYYY-MM-DD HH:mm:ss');
            this.targetModel.end_time = this.$helpers.setMomentValues(this.targetModel.end_time, 'second', 0, 'YYYY-MM-DD HH:mm:ss');
            this.createTarget(this.targetModel).then((response) => {
                const data = response.data.data,
                futureTarget = !this.$helpers.givenTimeRangeCheck(data.end_time, data.start_time);
                if (futureTarget) {
                    this.targetModel = {
                        type: 'C',
                        title: null,
                        start_time: new Date(),
                        end_time: this.$helpers.addHoursnDDateJs('hours',1),
                        unlocks: null,
                        field_1: null,
                        field_2: null,
                        field_3: null,
                        once: 0,
                        monthly: 0
                    }
                }
                this.responseUpdate(response, 'Started')
            }).catch(() => this.targetLoading = false);
        },
        responseUpdate(response, type) {
            if (!response || !response.data) {
                return;
            }
            this.$store.commit("snackbar/SHOW_MESSAGE", {
                text: `Challenge ${type} Successfully`,
                color: "success",
            });
            this.targetLoading = false;
            this.$emit('closeModal');
        },
        subChanges(type) {
            if( type) {
                this.targetModel.field_2 = null;
                return;
            }
            this.targetModel.field_3 = null;
        }
    },
    watch: {
        'currentTargetModel': function (newValue) {
            if (newValue) {
                this.targetModel.id = null;
                this.targetModel = this.$helpers.insertObjValue(this.targetModel, newValue);
                if (['C', 'U', 'M'].includes(this.targetModel.type)) {
                    this.targetModel.once = this.targetModel.field_2 ? 1 : 0;
                    this.targetModel.monthly = this.targetModel.field_3 ? 1 : 0;
                }
            }
        }
    }
}
</script>